<template>
  <div>
    <span
      ><img style="width: 100%" src="../assets/image/home/banner.jpg"
    /></span>
  </div>
  <div class="link-div">
    <span style="font-size: 13px">您当前的位置：</span>
    <el-link @click="toIndex">首页</el-link>
    <span style="font-size: 13px">&nbsp;>&nbsp;</span>
    <el-link @click="toCurrent">产品中心</el-link>
    <p class="link-p"></p>
  </div>
  <div class="module-div" style="margin-top: 50px; margin-bottom: 50px">
    <el-row>
      <el-col
        :span="5"
        v-for="data in productDataOne"
        :key="data"
        style="margin-right: 10px"
      >
        <el-card>
          <div class="grid-content bg-purple">
            <img
              :src="data.img"
              class="image"
              @click="toProductDetailsOne(data)"
            />
          </div>
          <div style="padding: 14px">
            <span>{{ data.name }}</span>
          </div>
          <div>
            <span class="product-describe">{{ data.describe }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="margin-top: 50px">
      <el-col
        :span="5"
        v-for="data in productDataTwo"
        :key="data"
        style="margin-right: 10px"
      >
        <el-card>
          <div class="grid-content bg-purple">
            <img
              :src="data.img"
              class="image"
              @click="toProductDetailsTwo(data)"
            />
          </div>
          <div style="padding: 14px">
            <span>{{ data.name }}</span>
          </div>
          <div>
            <span class="product-describe">{{ data.describe }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import qwpro from "@/assets/image/product-center/qwpro.png";
import qt from "@/assets/image/product-center/qt.png";
import smD2 from "@/assets/image/product-center/smD2.png";
import jhrwm from "@/assets/image/product-center/jhrwm.jpg";
import smzfzd from "@/assets/image/product-center/smzfzd.png";
import xbh from "@/assets/image/product-center/xbh.png";
export default {
  name: "About",
  components: {
    // HelloWorld,
  },

  data() {
    return {
      productDataOne: [
        {
          img: qwpro,
          name: "青蛙pro",
          date: "2021-09-16",
          describe:
            "双面屏，全面替代收银机，接入微信会员系统，打造智慧经营生态闭环。具有即插即用的特点，无需安装开发插件，轻松实现微信刷脸支付功能，同时也支持扫码支付。",
        },
        {
          img: qt,
          name: "支付宝-蜻蜓",
          date: "2021-09-16",
          describe:
            "1.POS模式:利用智能插件，无缝对接商户现有收银系统。2.独立模式:开发版蜻蜓，无需对接收银机，按键收款，有流水分润。",
        },
        {
          img: smD2,
          name: "商米D2",
          date: "2021-09-16",
          describe:
            "双面屏收银机，自带支付宝刷脸摄像头,三步解决收银问题，实现“又快、又准”收银。商品信息精准录入管理、销售，自动生成码，商品销售、采购、盘点无误。",
        },
        {
          img: jhrwm,
          name: "聚合二维码",
          date: "2021-09-16",
          describe:
            "融合支付宝、微信、京东、银联等主流支付为一体。方便快捷，提高商户日常收银、查账、对账效率，节省收银台空间。",
        },
      ],
      productDataTwo: [
        {
          img: smzfzd,
          name: "扫码支付终端",
          date: "2021-09-16",
          describe:
            "小巧时尚功能多样，可靠性强，是一款集扫码与非接于一体的新兴支付产品。可搭配ECR、POS终端、自动售卖机或电子秤等设备使用，也可以独立使用，满足各种非接和扫码支付。",
        },
        {
          img: xbh,
          name: "小白盒",
          date: "2021-09-16",
          describe:
            "智慧收银利器，解决二维码支付问题，即插即用。快速对接收银台、智能收银机、电脑和平板电脑，兼容性强，不限操作系统。",
        },
        {
          img: qt,
          name: "支付宝-蜻蜓",
          date: "2021-09-16",
          describe:
            "1.POS模式:利用智能插件，无缝对接商户现有收银系统。2.独立模式:开发版蜻蜓，无需对接收银机，按键收款，有流水分润。",
        },
        {
          img: qwpro,
          name: "青蛙pro",
          date: "2021-09-16",
          describe:
            "双面屏，全面替代收银机，接入微信会员系统，打造智慧经营生态闭环。具有即插即用的特点，无需安装开发插件，轻松实现微信刷脸支付功能，同时也支持扫码支付。",
        },
      ],
    };
  },
  methods: {
    toProductDetailsOne(data) {
      this.$router.push({
        path: "/ProductDetails",
        query: {
          name: data.name,
          img: data.img,
          describe: data.describe,
          date: data.date,
        },
      });
    },
    toProductDetailsTwo(data) {
      this.$router.push({
        path: "/ProductDetails",
        query: {
          name: data.name,
          img: data.img,
          describe: data.describe,
          date: data.date,
        },
      });
    },
    toIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    toCurrent() {
      this.$router.push({
        path: "/product",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/index.css";
.el-link {
  color: #000;
  margin-top: -3px;
}
.link-p {
  border-bottom: 1px solid #bbbbbdec;
}
.link-div {
  padding-left: 270px;
  text-align: left;
  margin-top: 30px;
  padding-right: 230px;
}
</style>